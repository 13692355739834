html {
    height: 100%;
    font-family: Instrument Sans SemiCondensed, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
}

h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

a,
a:hover,
a:visited,
a:focus,
a:active {
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    color: inherit;
}

/* Prevents ios-browser style overrides */
textarea,
input.text,
input[type='number'],
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
}

button {
    outline: none;
    border: none;
    cursor: pointer;
}

.primary-color {
    color: #FFFFFF !important;
}

.primary-inverted-color {
    color: #000000;
}

.primary-background {
    background-color: #2F23F2;
}

.primary-inverted-background {
    background-color: #FFFFFF;
}

.secondary-background {
    background-color: #4439F3;
}

.strong-weight {
    font-weight: 700;
}

.medium-weight {
    font-weight: 600;
}

.semi-bold-weight {
    font-weight: 500;
}

.xhuge-text {
    font-size: 140px;
    line-height: normal;
}

.huge-text {
    font-size: 100px;
    line-height: normal;
}

.xlarge-text {
    font-size: 56px;
    line-height: normal;
}

.semi-large-text {
    font-size: 40px;
    line-height: normal;
}

.large-text {
    font-size: 32px;
    line-height: normal;
}

.medium-text {
    font-size: 20px;
    line-height: 20px;
}

.xmedium-text {
    font-size: 18px;
    line-height: normal;
}

.small-text {
    font-size: 14px;
    line-height: 20px;
}

.standard-text {
    font-size: 16px;
    line-height: 20px;
}

.xxxsmall-text {
    font-size: 10px;
    line-height: 20px;
}

.on-top {
    z-index: 100;
}

.default-z-index {
    z-index: 1;
}

.draggable {
    cursor: grab;
}

.overflow-hidden {
    overflow: hidden !important;
}

.button-hover {
    &:hover {
        background-color: #FFFFFF;
        color: #4439F3 !important;
    }
}

.button-hover-border {
    &:hover {
        background-color: #FFFFFF;
        color: #2F23F2 !important;
        border: 1px solid #2F23F2;
    }
}

.button-hover-border-inverted {
    &:hover {
        background-color: #2F23F2;
        color: #FFFFFF !important;
        border: 1px solid #FFFFFF;
    }
}

.button-hover-inverted {
    &:hover {
        background-color: #4439F3;
        color: #FFFFFF !important;
    }
}


